import { gql } from "@apollo/client";
import { sortByName } from "helpers/utils";
import useQuery from "hooks/useQuery";
import { CustomType, DocumentType } from "__generated__/globalTypes";
import { DocumentTypesQuery, DocumentTypesQueryVariables } from "__generated__/DocumentTypesQuery";

interface NamedOpts {
  includeForms?: boolean; // include CDTs in forms?
  onlyDrawings?: boolean; // only return doc types that are drawings
}

const DOCUMENT_TYPES_QUERY = gql`
  query DocumentTypesQuery($projectId: String!) {
    project(id: $projectId) {
      id
      documentTypes {
        # TODO change BE to add id field
        name
        documentType
        customTypeId
        type
      }
    }
  }
`;

/**
 * Data hook to fetch document types
 * NOTE: Forms are excluded by default unless the `includeForms` option is passed.
 */
export default function useDocumentTypes(projectID: string, opts: NamedOpts = {}) {
  const { data, error, loading } = useQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(DOCUMENT_TYPES_QUERY, {
    fetchPolicy: "no-cache",
    variables: { projectId: projectID! },
  });

  let docTypes = (data?.project?.documentTypes || []).sort(sortByName);

  if (!opts.includeForms) {
    docTypes = docTypes.filter((docType) => docType.type !== CustomType.Form);
  }

  if (opts.onlyDrawings) {
    docTypes = docTypes.filter(
      (dt) => dt.documentType === DocumentType.ContractDrawing || dt.documentType === DocumentType.ShopDrawing
    );
  }
  return { docTypes, error, loading };
}
